import { graphql } from "gatsby"
import React from "react"
import { Heading, Text } from "rebass"
import Gallery from "../components/gallery"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const {
    albums: { edges },
  } = data
  const {
    node: { images },
  } = edges[0]

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Bed, Breakfast and More page" />
      <Heading p={[3, 4]} fontSize={[4, 5]} color="warning">
        B and More
      </Heading>

      <Text p={[3, 4]} fontSize={[2, 3]}>
        In a beautiful green area of Amsterdam, she offers a luxury room, in a
        very special artist's home. It is more than just a bed and free WiFi.
        For example, dinners on Fridays and romantic picnic baskets.
        <br />
        <br />
        There's excellent access to public transportation: bus, tram, and train.
        The house is very easy to reach by car as well. It is situated in a
        quiet, populated area, close to highway A10, 500 meters from Sloterdijk
        station, 5 minutes by train from the heart of the magical city of
        Amsterdam. From Sloterdijk station you'll have access to the train, and
        thus the possibility to explore other cities. Schiphol Airport is 10
        minutes away by train.
        <br />
        <br />
        Price: 130 euro per room per night, up to 2 persons. Minimum stay: 3
        nights.
      </Text>

      {images && <Gallery images={images} />}
    </Layout>
  )
}

export const bAndMorePageQuery = graphql`
  query BAndMore {
    site {
      siteMetadata {
        title
      }
    }
    albums: allContentfulAlbum(filter: { name: { eq: "room" } }) {
      edges {
        node {
          images {
            fluid(maxWidth: 950) {
              aspectRatio
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
              tracedSVG
            }
          }
        }
      }
    }
  }
`
