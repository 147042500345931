import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import Level from "react-bulma-components/lib/components/level"
import styled from "styled-components"
import { rhythm } from "../utils/typography"

const Header = styled(Level)`
  margin-bottom: 0px;
  background: ${props => props.theme.colors.warning};
  min-height: ${rhythm(3)};
`

const Button = styled(Level.Item)`
  &:not(:last-of-type) {
    border-right: 2px solid black;
  }
`

export default () => {
  return (
    <Header renderAs="nav" style={{ marginBottom: 0 }}>
      <Button>
        <AniLink fade to={"/"}>About Sigal</AniLink>
      </Button>

      <Button>
        <AniLink fade to={"/restaurant"}>Living Room / Restaurant</AniLink>
      </Button>

      <Button>
        <AniLink fade to={"/bandmore"}>B and More</AniLink>
      </Button>
    </Header>
  )
}
