import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import Menu from "react-burger-menu/lib/menus/slide"
import { menuStyles } from "../utils/styles"

export default ({ location }) => {
  const isMenuOpen = state => state.isOpen

  return (
    <Menu
      right
      width={`100%`}
      styles={menuStyles}
      isOpen={!isMenuOpen}
      onStateChange={isMenuOpen}
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
    >
      {location.pathname !== "/" && (
        <AniLink fade onClick={isMenuOpen} to={`/`}>
          About Sigal
        </AniLink>
      )}

      {location.pathname !== "/restaurant" && (
        <AniLink fade onClick={isMenuOpen} to={`/restaurant`}>
          Living Room Restaurant
        </AniLink>
      )}

      {location.pathname !== "/bandmore" && (
        <AniLink fade onClick={isMenuOpen} to={`/bandmore`}>
          B and More
        </AniLink>
      )}
    </Menu>
  )
}
