import Typography from "typography"

const typography = new Typography({
  baseFontSize: "1.25rem",
  baseLineHeight: 1.666,
  headerFontFamily: [
    "Kaushan Script",
    "Avenir Next",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "cursive",
  ],
  bodyFontFamily: ["Finger paint", "cursive"],
  a: {
    color: `white`,
    textShadow: "none",
    backgroundImage: "none",
  },
  "a:hover": {
    color: `#fff`,
  },
})

if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
