import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import { Box, Heading } from "rebass"
import styled, { ThemeProvider } from "styled-components"
import { theme } from "../utils/styles"
import { rhythm } from "../utils/typography"
import Navbar from "./navbar"
import Responsive from "react-responsive"
import Menu from "./menu"
import("../index.sass")

const Mobile = props => <Responsive {...props} maxWidth={899} />
const Default = props => <Responsive {...props} minWidth={900} />

const OuterContainer = styled(Box)`
  max-width: 4160px;
`

const Main = styled(BackgroundImage)`
  transition: all 1s ease-in;
`

const Hero = styled(Heading)`
  min-height: ${rhythm(2)};
  @media all and (min-width: 900px) {
    margin: 1rem;
  }
  margin: 2rem 3rem 1rem;
`

export default ({ location, children }) => {
  const { background } = useStaticQuery(query)

  return (
    <ThemeProvider theme={theme}>
      <Main
        Tag="section"
        fluid={background.childImageSharp.fluid}
        backgroundColor={theme.colors.primary}
      >
        <OuterContainer id="outer-container">
          <Mobile>
            <Menu location={location} />
          </Mobile>
          <Box
            mx="auto"
            pb={3}
            style={{
              maxWidth: rhythm(35),
              overflowX: `hidden`,
              background: `black`,
            }}
          >
            <Default>
              <Navbar location={location} />
            </Default>
            <Box
              style={{
                margin: `0 auto`,
                background: `black`,
              }}
            >
              <Hero fontFamily="Permanent Marker, cursive" fontSize={[48, 64]}>
                Sigal's Art B and More
              </Hero>
              <main>{children}</main>
            </Box>
          </Box>
        </OuterContainer>
      </Main>
    </ThemeProvider>
  )
}

const query = graphql`
  query Images {
    background: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "achtergrond" }
    ) {
      childImageSharp {
        fluid(maxWidth: 4160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
