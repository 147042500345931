import React from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import Gallery from "react-photo-gallery"
import { Box } from "rebass"

export default ({ images }) => {
  const photos = images.map(({ fluid }) => ({
    src: fluid.src,
    srcSet: fluid.srcSet,
    width: fluid.aspectRatio < 1 ? 3 : 4,
    height: fluid.aspectRatio < 1 ? 4 : 3,
  }))

  const [currentImage, setCurrentImage] = React.useState(0)
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false)

  const openLightbox = React.useCallback((_, { index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const columns = containerWidth => {
    let columns = 1
    if (containerWidth >= 500) columns = 2
    if (containerWidth >= 900) columns = 3
    return columns
  }

  return (
    <Box>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        direction="column"
        columns={columns}
      />
    </Box>
  )
}
